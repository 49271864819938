import React from 'react'

const Modal = props => {
    if (!props.show) {
        return null
    }
    return (
        <div className="modal" onClick={props.onClose}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <div className=
              "modal-header">
              <h4 className="modal-title">{props.title}</h4>
            </div>
            <div className="modal-body">
              <img
                src={props.src}
                className='img-responsive'
                alt={props.title}
              />
            </div>
            <div className="modal-footer">
              <button onClick={props.onClose} className="button">Закрыть</button>
            </div>
          </div>
        </div>
    )
}

export default Modal