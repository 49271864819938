export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Арендаторам</h2>
              <p>{props.data ? props.data.paragraph : "загрузка..."}</p>
              {/* <h3>Ознакомиться подробнее</h3> */}
              <div className="list-style">
                <div className="col-lg-4 col-sm-4 col-xs-4">
                <a
                  href='#contact'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Ознакомиться подробнее
                </a>{' '}
                  {/* <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                        <li key={`${d}-${i}`}>{d}</li>
                      ))
                      : "loading"}
                  </ul> */}
                </div>
                {/* <div className="col-lg-4 col-sm-4 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                        <li key={`${d}-${i}`}>{d}</li>
                      ))
                      : "loading"}
                  </ul>
                </div> */}
                {/* <div className="col-lg-4 col-sm-4 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why3.map((d, i) => (
                        <li key={`${d}-${i}`}>{d}</li>
                      ))
                      : "loading"}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
