import { YMaps, Map, Placemark } from 'react-yandex-maps';

export const Yamap = (props) => {
    const mapState = { center: [50.595, 36.66], zoom: 15 };
    return (
        <div id="yamap" className='text-center'>
            <div className="container">
                <div className='section-title'>
                    <h2>Карта</h2>
                </div>
                <div className='row'>
                    <YMaps>
                        <div className='container'>
                            <Map width={"100%"} height={"70%"} state={mapState} >
                                <Placemark
                                    defaultGeometry={[50.595180, 36.660058]}
                                    properties={{
                                        hintContent: "БЦ Каскад",
                                        iconCaption : 'БЦ Каскад'
                                    }}
                                />
                            </Map>
                        </div>
                    </YMaps>
                </div>
            </div>
        </div>
    );
};
