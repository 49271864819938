import React, {useState} from 'react'
import Modal from "./modal"

export const Image = ({ title, largeImage, smallImage }) => {
  const [show, setShow] = useState(false)
  return (
    <div className='portfolio-item'>
      <div className='hover-bg'>
        {' '}
        <a
          // href={largeImage}
          title={title}
          data-lightbox-gallery='gallery1'
          onClick={() => {setShow(true)}}
        >
          <Modal onClose={()=> setShow(false)} show={show} title={title} src={largeImage}/>
          <div className='hover-text'>
            <h4>{title}</h4>
          </div>
          <img
            src={smallImage}
            className='img-responsive'
            alt={title}
          />{' '}
        </a>{' '}
      </div>
    </div>
  )
}